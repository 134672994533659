import React from "react"
import Seo from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const CookiesPage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Banner content="This is the cookies page, but there are no chocolate chips to be found here" />
      </Layout>
    </>
  )
}

export default CookiesPage
